import React from 'react';
import styles from './Header.module.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

function Header() {
  function renderSocialMedia() {
    return (
      <div className={styles.socialMedia}>
        <FacebookIcon
          onClick={() => window.open('https://www.facebook.com/sosedanca/')}
          className={`${styles.socialMediaItem} ${styles.clickable}`}
        />
        <InstagramIcon
          onClick={() => window.open('https://www.instagram.com/sosedanca/')}
          className={`${styles.socialMediaItem} ${styles.clickable}`}
        />
      </div>
    );
  }

  function renderContacts() {
    return (
      <div className={styles.contacts}>
        <div
          className={`${styles.contact} ${styles.clickable}`}
          onClick={() => window.open('mailto:sosedanca@gmail.com')}
        >
          <AlternateEmailIcon fontSize="small" className={styles.contactItem} />
          <div className={styles.contactItem}>sosedanca@gmail.com</div>
        </div>
        <div
          className={`${styles.contact} ${styles.clickable}`}
          onClick={() => window.open('tel:917139015', '_self')}
        >
          <LocalPhoneIcon fontSize="small" className={styles.contactItem} />
          <div className={styles.contactItem}>917 139 015</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {renderSocialMedia()}
      {renderContacts()}
    </div>
  );
}

export default Header;
