import React, { useState, useMemo } from 'react';
import styles from './Home.module.css';
import logo from '../../logo.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

interface Props {
  className: string;
}

interface Lesson {
  location: Location | null;
  weekDay: number;
  hour: number;
  minutes: number;
  duration: number;
}

interface NextLesson extends Lesson {
  date: Date;
}

interface Location {
  description: string;
  gps: string;
}

const locations: { [name: string]: Location } = {
  paçosDeBrandão: {
    description: 'Paços de Brandão, Academia de Música',
    gps: 'https://goo.gl/maps/ZZVduknFpYY6Nrk48',
  },
  lourosa: {
    description: 'Lourosa, Salão Polivalente da Junta de Freguesia',
    gps: 'https://shorturl.at/krABJ',
  },
  rioMeão: {
    description: 'Rio Meão, Pavilhão das Coletividades',
    gps: 'https://shorturl.at/jJKL1',
  },
  feira: {
    description: 'Santa Maria da Feira, Cercifeira',
    gps: 'https://goo.gl/maps/dkq8EUfN5Tst2wW27',
  },
  ovar: {
    description: 'Ovar, Academia Espaço Aberto',
    gps: 'https://shorturl.at/biDJ8',
  },
  seixezelo: {
    description: 'Seixezelo, Plim Sports Bar',
    gps: 'https://shorturl.at/mtGJ6'
  },
};

export const schedule: Lesson[] = [
  {
    location: locations.paçosDeBrandão,
    weekDay: 1,
    hour: 21,
    minutes: 0,
    duration: 2,
  },
  {
    location: locations.lourosa,
    weekDay: 2,
    hour: 21,
    minutes: 0,
    duration: 2,
  },
  {
    location: locations.feira,
    weekDay: 3,
    hour: 21,
    minutes: 0,
    duration: 2,
  },
  {
    location: locations.seixezelo,
    weekDay: 4,
    hour: 21,
    minutes: 30,
    duration: 1,
  },
  {
    location: locations.rioMeão,
    weekDay: 5,
    hour: 21,
    minutes: 0,
    duration: 2,
  },
  {
    location: locations.ovar,
    weekDay: 6,
    hour: 15,
    minutes: 30,
    duration: 1.5,
  },
];

export const ptWeekDays = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feira',
  'quinta-feira',
  'sexta-feira',
  'sábado',
];

const getLessonTimestamp = (dateOfReference: Date, lesson: Lesson) => {
  const date = new Date();
  if (lesson.hour + lesson.duration > dateOfReference.getHours()) {
    date.setDate(
      dateOfReference.getDate() +
        ((7 + lesson.weekDay - dateOfReference.getDay()) % 7)
    );
  } else {
    date.setDate(
      dateOfReference.getDate() +
        ((7 - dateOfReference.getDay()) % 7) +
        lesson.weekDay
    );
  }
  date.setHours(lesson.hour, lesson.minutes, 0, 0);
  return date.getTime();
};

export const getNextLesson = (dateOfReference: Date): NextLesson => {
  const weekSchecule: { [name: string]: Lesson } = schedule.reduce(
    (week, lesson) => {
      const timestamp = getLessonTimestamp(dateOfReference, lesson);
      return { ...week, [timestamp]: lesson };
    },
    {}
  );

  const nextTimestamp = Math.min(
    ...Object.keys(weekSchecule).map((timestamp) => +timestamp)
  );
  return {
    date: new Date(nextTimestamp),
    ...weekSchecule[nextTimestamp.toString()],
  };
};

function Home(props: Props) {
  const [name, setName] = useState<string>();
  const [contact, setContact] = useState<string>();
  const [question, setQuestion] = useState<string>();

  const sendFreeLesson = () => {
    const subject = 'Inscrição para aula experimental';
    const body = `Nome: ${name ?? '?'}; Contacto: ${contact ?? '?'};`;
    window.open(`mailto:sosedanca@gmail.com?subject=${subject}&body=${body}`);
  };

  const nextLesson = useMemo(() => getNextLesson(new Date()), []);

  const sendQuestion = () => {
    window.open(
      `mailto:sosedanca@gmail.com?subject=Questão&body=${question ?? '?'}`
    );
  };

  function renderLocation(props: { lesson: Lesson; short?: boolean }) {
    if (!props.lesson.location) {
      return 'Por definir';
    }

    return (
      <a
        href={props.lesson.location.gps}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.short
          ? props.lesson.location.description.substring(
              0,
              props.lesson.location.description.indexOf(',')
            )
          : props.lesson.location.description}
      </a>
    );
  }

  return (
    <div className={`${styles.root} ${props.className}`}>
      <div className={styles.banner}>
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.text}>
          <p className={styles.title}>Só se Dança</p>
          <p className={styles.subtitle}>ESCOLA DE DANÇA</p>
        </div>
      </div>
      <div className={styles.statement}>
        <div className={styles.statementLine}>
          A Dança transporta-nos numa viagem alucinante de sensações
          maravilhosas.
        </div>
        <div className={styles.statementLine}>
          Os movimentos que o corpo descreve ao sabor da música libertam
          sensualidade e elegância, transmitindo um bem-estar físico e psíquico
          que só quem a pratica conhece.
        </div>
        <div className={styles.statementLine}>
          Tonifica os músculos, modela o corpo, dá-nos alegria, amigos, saúde e,
          sobretudo, felicidade.
        </div>
        <div className={styles.statementLine}>
          Venha sentir a magia da dança e passar bons momentos. Mesmo achando
          que não tem jeito ou que possui dois pés esquerdos, apareça, sozinho
          ou acompanhado e vai ver que é mais fácil do que parece.
        </div>
        <div className={styles.statementLine}>
          A Dança é o melhor exercício para o corpo, libertação para a mente e a
          verdadeira terapia para a alma.
        </div>
      </div>
      <div className={styles.menu}>
        <div className={styles.menuItem}>
          <div className={styles.menuItemTitle}>Aula Gratuita</div>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="Contacto"
            variant="outlined"
            fullWidth
            value={contact}
            onChange={(event) => setContact(event.target.value)}
            error={isNaN(Number(contact ?? 0))}
            helperText={
              isNaN(Number(contact ?? 0))
                ? 'Número de contacto inválido'
                : undefined
            }
          />
          <Button
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            fullWidth
            style={{
              alignItems: 'initial',
              justifyContent: 'space-between',
              backgroundColor: '#282c34',
              color: '#4690d3',
            }}
            onClick={sendFreeLesson}
          >
            Experimentar
          </Button>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.menuItemTitle}>Questão</div>
          <TextField
            label="Questão"
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
          />
          <Button
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            fullWidth
            style={{
              alignItems: 'initial',
              justifyContent: 'space-between',
              backgroundColor: '#282c34',
              color: '#4690d3',
            }}
            onClick={sendQuestion}
          >
            Perguntar
          </Button>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.menuItemTitle}>Próxima Aula</div>
          <div className={styles.nextLesson}>
            A próxima aula é dia {nextLesson.date.getDate()},{' '}
            {ptWeekDays[nextLesson.date.getDay()]}, às{' '}
            {nextLesson.date.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
            <span>, em </span>
            {nextLesson.location && renderLocation({ lesson: nextLesson })}!
          </div>
          <div className={styles.menuItemTitle}>Aparece!</div>
        </div>
        <div className={styles.scheduleItem}>
          <div className={styles.menuItemTitle}>Horário</div>
          <div className={styles.schedule}>
            <table>
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Local</th>
                  <th>Hora</th>
                </tr>
              </thead>
              <tbody>
                {schedule.map((lesson, index) => {
                  const date = new Date(getLessonTimestamp(new Date(), lesson));
                  const weekday = date.toLocaleString('pt-pt', {
                    weekday: 'short',
                  });
                  const hour = date.toLocaleTimeString([], {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                  });
                  return (
                    <tr key={index}>
                      <td>{weekday[0].toUpperCase() + weekday.substring(1)}</td>
                      <td>{renderLocation({ lesson, short: true })}</td>
                      <td>{hour}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
